<template>
	<v-app class="fs_main">
		<v-container>
			<v-row>
				<v-col>
					<div class="fs_main_header">
						<h1>マイページ</h1>
					</div>
				</v-col>
			</v-row>

			<v-row class="fs_mypage_row">
				<v-col>
					<header class="fs_block_header">
						<v-avatar size="80">
							<img :src="user.image" :alt="user.name" />
						</v-avatar>
					</header>

					<v-simple-table class="v-simple-table" dark>
						<tbody>
							<tr>
								<th style="width: 120px">名前</th>
								<td>
									<span style="margin-right: 15px;">{{ user.name }}</span>
									<v-btn v-if="loginType == 'mail'" class="primary" @click="nameDialog=true">変更</v-btn>
								</td>
							</tr>
							<tr>
								<th style="width: 120px">E-mail</th>
								<td>
									{{ user.email }}
								</td>
							</tr>
							<!-- <tr>
								<th style="width: 120px">備考</th>
								<td>
									{{ user.statusMessage }}
								</td>
							</tr> -->
							<tr v-if="loginType == 'mail'">
								<th>パスワード</th>
								<td>
									<v-btn class="primary" @click="dialog=true">変更</v-btn>
								</td>
							</tr>
							<tr>
								<th style="width: 120px">プロジェクト</th>
								<td v-if="project.id">
									{{ project.name }}
								</td>
								<td v-else>
									<div
										class="d-flex flex-row align-baseline"
										style="margin-top: 20px; width: 180px"
									>
										<v-text-field label="code" v-model="code" outlined dense />
										<v-btn
											class="ma-2 pa-2"
											@click="submitCode()"
											small
											outlined
										>
											送信
										</v-btn>
									</div>
								</td>
							</tr>
						</tbody>
					</v-simple-table>
				</v-col>
			</v-row>
		</v-container>
		<v-container>
			<v-dialog v-model="dialog" max-width="500px">
				<v-card>
					<v-card-title>
						<span class="headline">パスワード変更</span>
					</v-card-title>
					<v-card-text>
						<v-container>
							<v-text-field
								label="現在のパスワード"
								class="textfield"
								v-model="oldPassword"
								background-color="white"
								clearable
								:append-icon="passwordShow1 ? 'mdi-eye' : 'mdi-eye-off'"
								:type="passwordShow1 ? 'text' : 'password'"
								filled
								@click:append="passwordShow1 = !passwordShow1"
							></v-text-field>
							<v-text-field
								label="新しいパスワード"
								class="textfield"
								v-model="newPassword"
								background-color="white"
								clearable
								:append-icon="passwordShow2 ? 'mdi-eye' : 'mdi-eye-off'"
								:rules="[rules.min, rules.required]"
								:type="passwordShow2 ? 'text' : 'password'"
								hint="パスワードは8文字以上"
								filled
								@click:append="passwordShow2 = !passwordShow2"
							></v-text-field>
						</v-container>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn
							color="blue darken-1"
							text
							@click="close"
						>
							キャンセル
						</v-btn>
						<v-btn color="blue darken-1" text @click="save">
							保存
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
			<v-dialog v-model="nameDialog" max-width="500px">
				<v-card>
					<v-card-title>
						<span class="headline">名前変更</span>
					</v-card-title>
					<v-card-text>
						<v-container>
							<v-text-field
								label="名前"
								class="textfield"
								v-model="name"
								background-color="white"
								clearable
								filled
							></v-text-field>
						</v-container>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn
							color="blue darken-1"
							text
							@click="close"
						>
							キャンセル
						</v-btn>
						<v-btn color="blue darken-1" text @click="saveName">
							保存
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-container>
		<v-footer>
			©2021 <a href="https://www.vegetalia.co.jp/">vegetalia</a>, inc.
		</v-footer>
	</v-app>
</template>

<script>
import axios from "axios";
import md5 from "js-md5";

export default {
	components: {},
	computed: {},
	mounted() {
		this.name = this.user.name;
		axios.defaults.headers.common["Authorization"] =
			"Bearer " + this.$store.state.auth.data.token;
	},
	methods: {
		close() {
			this.dialog = false;
			this.nameDialog = false;
		},
		save() {
			this.showLoading();
			const params = { oldPassword: md5(this.oldPassword), newPassword: md5(this.newPassword) };
			console.log(JSON.stringify(params));
			axios
				.post(
					"https://" + this.config.defaultOriginString + "/api/register",
					params
				)
				.then((res) => {
					// 成功した場合
					if (res && res.data && res.data.status == "success") {
						this.hideLoading();
						console.log("Success: " + JSON.stringify(res));
						this.dialog = false;
						alert("パスワードを変更しました");
					} else {
						this.hideLoading();
						alert("パスワードが有効ではありません");
						console.log("Fail: " + JSON.stringify(res));
					}
				})
				.catch((error) => {
					this.hideLoading();
					alert("不明なエラーが発生しました");
					console.log("Error: " + error);
				});
		},
		saveName() {
			this.showLoading();
			const params = { name: this.name };
			console.log(JSON.stringify(params));
			axios
				.post(
					"https://" + this.config.defaultOriginString + "/api/register",
					params
				)
				.then((res) => {
					// 成功した場合
					if (res && res.data && res.data.status == "success") {
						this.hideLoading();
						console.log("Success: " + JSON.stringify(res.data));
						this.nameDialog = false;
						this.$store.state.user.data.name = res.data.appInfo.user.userName.S;
						this.user.name = this.$store.state.user.data.name;
						this.name = this.user.name;
						alert("名前を変更しました");
					} else {
						this.hideLoading();
						alert("名前が有効ではありません");
						console.log("Fail: " + JSON.stringify(res));
					}
				})
				.catch((error) => {
					this.hideLoading();
					alert("不明なエラーが発生しました");
					console.log("Error: " + error);
				});
		},
		submitCode: function () {
			this.showLoading();
			const params = { user: this.user, code: this.code };
			console.log(JSON.stringify(params));
			axios
				.post(
					"https://" + this.config.defaultOriginString + "/api/register",
					params
				)
				.then((res) => {
					// 成功した場合
					if (res && res.data && res.data.status == "success") {
						this.hideLoading();
						console.log("Success: " + JSON.stringify(res));
						this.$store.dispatch("project/setData", res.data.appInfo.project);
						this.$store.dispatch("places/setData", res.data.appInfo.places);
						this.$store.dispatch("auth/setData", {
							tokenType: this.$store.state.auth.data.tokenType,
							token: res.data.token,
						});
					} else {
						this.hideLoading();
						alert("招待コードが有効ではありません");
						console.log("Fail: " + JSON.stringify(res));
					}
				})
				.catch((error) => {
					this.hideLoading();
					alert("不明なエラーが発生しました");
					console.log("Error: " + error);
				});
		},
		showLoading: function () {
			this.$emit("loadShowHide", true);
		},
		hideLoading: function () {
			this.$emit("loadShowHide", false);
		},
	},
	data() {
		return {
			code: "",
			user: this.$store.state.user.data,
			loginType: this.$store.state.auth.data.tokenType,
			project: this.$store.state.project.data,
			oldPassword: "",
			newPassword: "",
			dialog: false,
			name: "",
			nameDialog: false,
			passwordShow1: false,
			passwordShow2: false,
			rules: {
				required: v => !!v || '必須項目',
				min: v => v.length >= 8 || "パスワードは8文字以上",
			},
		};
	},
};
</script>

<style scoped>
.v-simple-table tbody tr:hover:not(.v-simple-table__expanded__content) {
	background: rgba(0, 0, 0, 0) !important;
}
</style>